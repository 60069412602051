import React from 'react';
import { IoIosMail } from 'react-icons/io';
import { BiSolidCool } from 'react-icons/bi';
import { RiMoneyEuroCircleFill } from 'react-icons/ri';
import { FiStar } from "react-icons/fi";

const Services = () => {

  return (
    <div id='services' className='bg-gradient-to-b from-white to-black w-full py-[10rem] px-4'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 gap-16'>
        <div className='w-full text-white bg-black p-12 my-16 flex flex-col rounded-3xl text-center payment-block'>
          <IoIosMail className='md:w-40 w-20 mx-auto mt-[-2rem]' size={100} />
          <h1 className='font-bold text-red-600 mt-8 md:text-xl sm:text-lg'>RESERVATION</h1>
          <p className='py-2 font-medium text-white text-sm md:text-base'>Reservez en ligne directement via le formulaire de réservation ou par téléphone.</p>
        </div>
        <div className='w-full text-white bg-black p-12 my-16 flex-col flex rounded-3xl text-center payment-block'>
          <BiSolidCool className='w-20 md:w-30 mx-auto mt-[-2rem]' size={100} />
          <h1 className='font-bold text-red-600 mt-8 md:text-xl sm:text-lg'>DECOUVERTE</h1>
          <p className='py-2 font-medium text-white text-sm md:text-base'>Profitez des services exclusifs à bord du véhicule.</p>
        </div>
        <div className='w-full text-white bg-black p-12 my-16 flex-col flex rounded-3xl text-center payment-block'>
          <RiMoneyEuroCircleFill className='w-20 md:w-30 mx-auto mt-[-2rem]' size={100} />
          <h1 className='font-bold text-red-600 mt-8 md:text-xl sm:text-lg'>PAIEMENT</h1>
          <p className='py-2 font-medium text-white text-sm md:text-base'>Réglez votre course sur place en espèces et/ou par carte bancaire.</p>
        </div>
        <div className='w-full text-white bg-black p-12 my-16 flex-col flex rounded-3xl text-center payment-block'>
          <FiStar className='w-20 md:w-30 mx-auto mt-[-2rem]' size={100} />
          <h1 className='font-bold text-red-600 mt-8 md:text-xl sm:text-lg'>AVIS CLIENT</h1>
          <p className='py-2 font-medium text-white text-sm md:text-base'>Partagez votre expérience. Votre avis compte pour moi!</p>
        </div>
      </div>
      <div className='mx-auto max-w-[1240px] border-b-4 border-white animate-pulse py-[3rem]'></div>
    </div>
  );
};

export default Services;