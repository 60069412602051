import React, { useState } from 'react';
import talismanImage from '../images/Talisman2022.jpg';
import peugeot3008Image from '../images/peugeot-3008GRIS1.png';
import { IoIosWifi } from "react-icons/io";
import { GiWaterBottle } from "react-icons/gi";
import { MdOutlineLuggage } from "react-icons/md";
import { GiNewspaper } from "react-icons/gi";
import { IoLogoApple } from "react-icons/io5";
import { FaPeopleRoof } from "react-icons/fa6";

const VehicleInfo = () => {
  const vehicles = [
    {
      title: 'Renault Talisman',
      image: talismanImage,
      details: "La Renault Talisman est une berline élégante et confortable, idéale pour vos trajets professionnels ou personnels. Avec son design raffiné et ses fonctionnalités avancées, elle offre une expérience de conduite exceptionnelle.",
      services: ["WiFi & Bluetooth", "3 bagages & 3 sièges", "Bouteille d'eau & collations", "Journaux quotidiens"]
    },
    {
      title: 'Peugeot 3008 GTLine',
      image: peugeot3008Image,
      details: "La Peugeot 3008 GTLine est un SUV compact polyvalent, parfait pour les déplacements en ville ou les escapades en famille. Dotée d'un design dynamique et de technologies innovantes, elle assure confort et sécurité à bord.",
      services: ["Apple CarPlay & Bluetooth", "4 bagages & 3 sièges", "Bouteille d'eau & collations", "Toit panoramique"]
    },
  ];

  const [showServices, setShowServices] = useState(Array(vehicles.length).fill(false));

  const toggleServices = (index) => {
    const newShowServices = [...showServices];
    newShowServices[index] = !newShowServices[index];
    setShowServices(newShowServices);
  };

  return (
    <div id='véhicules' className="w-full max-w-[1240px] md:rounded-2xl bg-white mx-auto py-12 my-32 px-6 text-center font-extralight mb-16">
      <div className='py-4'>
        <h1 className="text-2xl font-bold mb-8 uppercase md:text-3xl sm:text-3xl">Véhicules à disposition</h1> 
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
        {vehicles.map((vehicle, index) => (
          <div key={index} className="flex flex-col items-center">
            <img src={vehicle.image} alt={vehicle.title} className="w-full h-auto object-cover object-center mb-10 md:rounded-2xl" />
            <h2 className="text-xl font-bold mb-6">{vehicle.title}</h2>
            <p>{vehicle.details}</p>
            <button className="hover:bg-red-600 bg-black hover:scale-105 duration-500 rounded-2xl text-white font-medium my-6 py-2 px-4 mb-6 ease-in-out uppercase" onClick={() => toggleServices(index)}>En savoir +</button>
            {showServices[index] && (
              <div className="text-center mt-2">
                <div>
                  {vehicle.services.map((service, serviceIndex) => (
                    <div key={serviceIndex}>
                      {serviceIndex === 0 && vehicle.title === 'Renault Talisman' && (
                        <IoIosWifi className='inline-block mr-2 mb-1' size={20}/>
                      )}
                      {serviceIndex === 1 && (
                        <MdOutlineLuggage className='inline-block mr-2 mb-1' size={20}/>
                      )}
                      {serviceIndex === 2 && (
                        <GiWaterBottle className='inline-block mr-2 mb-1' size={20}/>
                      )}
                      {serviceIndex === 3 && vehicle.title === 'Renault Talisman' && (
                        <GiNewspaper className='inline-block mr-2 mb-1' size={20}/>
                      )}
                      {serviceIndex === 0 && vehicle.title === 'Peugeot 3008 GTLine' && (
                        <IoLogoApple className='inline-block mr-2 mb-1' size={20}/>
                      )}
                      {serviceIndex === 3 && vehicle.title === 'Peugeot 3008 GTLine' && (
                        <FaPeopleRoof className='inline-block mr-2 mb-1' size={20}/>
                      )}
                      {service}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default VehicleInfo;
