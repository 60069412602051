import React from "react";
import { FaHome } from "react-icons/fa";
import { Link, animateScroll as scroll } from "react-scroll";
import logojactransports from '../images/logojactransports.png'

const Start = () => {
    
    const scrollToSection = (sectionId, offset) => {
        scroll.scrollTo(sectionId, {
          duration: 1000,
          smooth: "easeInOutQuart",
          offset: offset || 0
        });
      };

      const links = [
        {
          id: 1,
          link: <Link to="home" smooth={true} onClick={() => scrollToSection("home")}><FaHome size={24} /></Link>,
        },
        {
          id: 2,
          link: <Link to="about" smooth={true} onClick={() => scrollToSection("about", -100)}>Qui sommes-nous ?</Link>,
        },
        {
          id: 3,
          link: <Link to="services" smooth={true} onClick={() => scrollToSection("services")}>Services</Link>,
        },
        {
          id: 4,
          link: <Link to="réservation" smooth={true} onClick={() => scrollToSection("réservation")}>Réservation</Link>,
        },
        {
          id: 5,
          link: <Link to="véhicules" smooth={true} onClick={() => scrollToSection("véhicules")}>Véhicules</Link>,
        },
        {
          id: 6,
          link: <Link to="contact" smooth={true} onClick={() => scrollToSection("contact")}>Contact</Link>,
        },
      ];      

    return (
        <div>
            <div className="text-white fixed top-0 left-0 w-full z-10 bg-black">
                <div className="flex justify-between items-center h-16 max-w-[1240px] mx-auto px-4">
                    <img src={logojactransports} alt="JAC TRANSPORTS" className="w-32 h-24"></img>
                    <h1 className="w-full text-2xl m-2 font-bold">JAC<span className="ml-2">TRANSPORTS</span></h1>
                    <ul className="hidden lg:flex flex-shrink-0">
                        {links.map(({ id, link }) => (
                            <li key={id} className="cursor-pointer px-4 capitalize font-medium hover:scale-105 hover:text-red-600 duration-200">{link}</li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className="bg-red-600 text-black text-center p-2 fixed top-16 left-0 w-full z-20 font-semibold uppercase md:text-base sm:text-sm text-xs">
                Un VTC ? Appeler maintenant au <a href="tel:0629481161" className="text-white hover:text-black hover:scale-105 duration-200">06 29 48 11 61</a>
            </div>
            <div className="mt-5"></div>
        </div>
    );
};

export default Start;
