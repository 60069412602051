import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

const Reservation = () => {
  const form = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    const name = form.current.user_name.value;
    const email = form.current.user_mail.value;
    const tel = form.current.user_tel.value;
    const message = form.current.user_message.value;

    const isNameValid = !!name;
    const isEmailValid = validateEmail(email);
    const isTelValid = !!tel;
    const isMessageValid = !!message;

    setIsFormValid(isNameValid && isEmailValid && isTelValid && isMessageValid);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    validateForm();

    if (isFormValid) {
      emailjs
        .sendForm('service_18u6ef6', 'template_vlojgur', form.current, {
          publicKey: 'egjBrdCI14w7jqTOr',
        })
        .then(
          () => {
            setModalMessage('Réservation envoyée avec succès!');
            setIsModalOpen(true);
            console.log('SUCCES!');
          },
          (error) => {
            setModalMessage('Échec de l\'envoi. Veuillez réessayer plus tard.');
            setIsModalOpen(true);
            console.log('FAILED...', error.text);
          },
        );
    } else {
      setModalMessage('Veuillez remplir tous les champs du formulaire et entrez une adresse mail valide.');
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div id='réservation' className='text-black bg-black w-full py-0 px-6'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-3'>
        <div className='md:col-span-2'>
          <h1 className='text-white font-bold md:text-4xl sm:text-3xl py-4 text-2xl'>Votre voyage commence par une simple réservation.</h1>
          <h3 className='text-red-600 font-medium mb-[120px] md:py-6 py-2 text-base sm:text-xl md:text-2xl'>Reservez votre trajet dès maintenant !</h3>
          <p className='text-white md:text-base sm:text-lg text-base md:py-14'><span className='text-red-600'>*</span>Veuillez vous assurer que tous les champs du formulaire de réservation sont correctement remplis.</p>
        </div>
        <div className='sm:flex-col text-center'>
          <div className='flex flex-col sm:flex-row items-center gap-6'>
            <form ref={form} onSubmit={sendEmail}>
              <label>Name</label>
              <input className='p-3 w-full rounded-md text-black' type="text" name="user_name" placeholder='Entrez votre nom*'onChange={validateForm} />
              <label>Email</label>
              <input className='p-3 w-full rounded-md text-black' type="email" name="user_mail" placeholder='Entrez votre E-mail*' onChange={validateForm} />
              <label>Tel</label>
              <input className='p-3 w-full rounded-md text-black' type="tel" name="user_tel" placeholder='Entrez votre Numéro de téléphone*' onChange={validateForm}></input>
              <label>Message</label>
              <textarea className='p-3 w-full rounded-md text-black resize my-3 sm:my-3 md:my-3' rows='4' placeholder='Entrez votre Message*' name="user_message" onChange={validateForm} />
              <input type="submit" value="Send" className={`hidden ${!isFormValid && 'cursor-not-allowed'}`} disabled={!isFormValid} />
              <button className={`p-3 rounded-2xl my-3 w-[300px] md:w-[300px] mx-auto font-medium uppercase bg-red-600 text-white hover-scale-105 duration-500 hover:bg-red-600 hover:text-black cursor-pointer ${!isFormValid}`} onClick={sendEmail}>Envoyer votre reservation</button>
            </form>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="absolute inset-0 bg-gray-800 opacity-75" onClick={closeModal}></div>
          <div className="z-10 bg-white p-6 rounded-md">
            <p className='font-medium'>{modalMessage}</p>
            <button className="bg-red-600 text-white px-4 py-2 mt-4 rounded-md mx-auto font-medium flex uppercase hover:scale-105 duration-500 hover:bg-black hover:text-red-600" onClick={closeModal}>Fermer</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Reservation;
