import React from 'react';
import { Link } from 'react-router-dom';
import logojactransports from '../images/logojactransports.png';

const LegalPage = () => {
  return (
    <div>
      <div className='top-0 left-0 w-full z-10 bg-black'>
        <div className="flex justify-between items-center h-16 max-w-[1240px] mx-auto px-4">
          <img src={logojactransports} alt="JAC TRANSPORTS" className="w-28 h-24"></img>
          <h1 className="w-full text-2xl m-2 font-bold text-white">
            JAC<span className="ml-2">TRANSPORTS</span>
          </h1>
        </div>
      </div>

      <div className='top-0 left-0 w-full z-10 bg-white relative'>
        <div className="flex justify-between items-center h-24 max-w-[1240px] mx-auto px-6">
          <h1 className='text-black md:text-2xl font-bold text-xl'>Mentions légales</h1>
          <Link to="/" className='text-right relative font-bold text-black hover:scale-105 duration-500'>Accueil<div className="bar"></div></Link>
        </div>
      </div>

      <div className='py-20 px-6 max-w-[1240px] mx-auto'>
        <div className='text-white font-extralight'>
          <h3 className='uppercase font-bold py-2'>JAC TRANSPORTS</h3>
          <p>Julien Akdogan</p>
          <p>julienakdogan.privatedriver@gmail.com</p>
          <p>06 29 48 11 61</p>
          <p>233 rue du Faubourg Saint Martin 75010 Paris</p>
          <p>Siret: 84772508200014</p>
          <p></p>
        </div> 
        <div className='text-white font-extralight py-12'>
          <h3 className='uppercase font-bold py-2'>Editeur du site</h3>
          <p>Kévin Berkant Akdogan</p>
          <p>akdogankevin@gmail.com</p>
          <p>06 58 32 21 71</p>
        </div>
        <div className='text-white font-extralight'>
          <h3 className='uppercase font-bold py-2'>Hébergeur</h3>
          <p>Hostinger</p>
          <p>www.hpanel.hostinger.com</p>
        </div> 
      </div>
    </div>
  );
};

export default LegalPage;
