import React from "react";

const CompanyPresentation = () => {
  return (
    <div id="about" className="text-white w-full max-w-[1000px] mx-auto flex flex-col my-20 px-16 font-extralight text-center">
      <div className="relative">
        <h1 className="font-bold mb-16 md:text-3xl sm:text-2xl text-lg uppercase text-center">À propos de JAC TRANSPORTS</h1>
        <div className="absolute bottom-12 left-1/2 transform -translate-x-1/2 bg-red-600 h-1 w-60 sm:w-70 md:w-80"></div>
      </div>
      <p className="md:text-xl sm:text-lg text-base mb-10">
        Je me présente Julien AKDOGAN, chauffeur de maitre depuis des années et spécialisé dans le transport de personnes vers les aéroports et gares dans toute l'Ile-de-France. 
        Fort de nombreuses années d'expérience, je lance ma société de VTC que je nomme <span className="font-semibold text-red-600">JAC TRANSPORTS</span>.
      </p>
      <p className="md:text-xl sm:text-lg text-base mb-10">
        Mon engagement est de vous fournir un service sur mesure de qualité, alliant confort, sécurité et élégance permettant de répondre aux besoins
        de mes clients les plus exigeants. Reserver un VTC devient alors la solution la plus adaptée car mes tarifs sont determinés à l'avance, sans suppléments, même en cas de retard.
      </p>
      <p className="md:text-xl sm:text-lg text-base mb-10">La fierté de mes collaborations s'exprime à travers des partenariats prestigieux, incluant la célèbre marque de luxe{" "}
        <a href="https://www.hermes.com/fr/fr/" target="_blank" rel="noopener noreferrer" className="text-orange-600 font-semibold">HERMES</a>. Ainsi, je peux vous assurer une expérience de transport exceptionnelle et haut de gamme.
      </p>
    </div>
  );
};

export default CompanyPresentation;