import React from "react";
import talismanImage from "../images/talisman.jpg";
import Typed from "react-typed";
import { Link, animateScroll as scroll } from "react-scroll";

const Header = () => {
    const containerStyle = {
        position: "relative",
        overflow: "hidden",
    };

    const overlayStyle = {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.125)",
        zIndex: 1,
    };

    const scrollToReservation = (sectionId) => {
        scroll.scrollTo(sectionId, {
            duration: 1000,
            offset: -16,
            smooth: 'easeInOutQuart',
        });
    };

    const textContainerStyle = {
        position: "relative",
        zIndex: 2,
        textAlign: "center",
        marginTop: "16px",
    };

    return (
        <div id="home" className="text-white relative" style={containerStyle}>
            <img src={talismanImage} alt="Talisman" className="w-full h-full object-cover absolute inset-0 opacity-70"></img>
            <div style={overlayStyle}></div>
            <div className="max-w-[800px] mt-[-95px] w-full h-screen mx-auto text-center flex flex-col justify-center my-header-section" style={textContainerStyle}>
                <h1 className="font-bold md:text-6xl sm:text-5xl text-4xl md:py-6 py-4 text-shadow-lg"><span className="text-red-600">Bienvenue chez</span> JAC TRANSPORTS</h1>
                <div className="flex justify-center items-center md:my-4 py-4">
                    <p className="md:text-4xl sm:text-2xl text-xl font-semibold mb-5">Optez pour l'excellence, où chaque trajet devient une satisfaction <Typed className="md:text-4xl sm:text-2xl text-xl font-semibold mb-10 text-red-600" strings={['sans égale.', 'incomparable.', 'unique.']} typeSpeed={100} backSpeed={120} loop /></p>
                </div>
                <Link to="réservation" smooth={true} onClick={() => scrollToReservation("réservation")} className="bg-red-600 hover:scale-105 duration-500 hover:bg-black hover:text-red-600 w-[200px] rounded-2xl font-medium my-8 mx-auto py-3 text-white transition cursor-pointer">RESERVATION</Link>
            </div>
        </div>
    );
};

export default Header;
