import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CiMail } from "react-icons/ci";
import { CiMobile3 } from "react-icons/ci";
import { MdOutlinePlace } from "react-icons/md";
import { IoLogoInstagram } from "react-icons/io5";
import { FaRegCopyright } from "react-icons/fa";
import hermes from "../images/hermes.jpg";
import lecab from "../images/lecab.png";
import marcel from "../images/marcel.png";

function Contact() {
  const [hoveredPartner, setHoveredPartner] = useState(null);

  const handlePartnerMouseEnter = (name) => {
    setHoveredPartner(name);
  };

  const handlePartnerMouseLeave = () => {
    setHoveredPartner(null);
  };

  const emailSubject = encodeURIComponent("Demande d'information et/ou de réservation");
  const emailBody = encodeURIComponent("Bonjour Julien, \n\n");
  const emailLink = `mailto:julienakdogan.privatedriver@gmail.com?subject=${emailSubject}&body=${emailBody}`;

  const googleMapsLink = "https://www.google.com/maps/search/?api=1&query=233+rue+du+faubourg+Saint+Martin,+75010+Paris";

  const InstagramLink = "https://www.instagram.com/jactransports_/";

  const partenaires = [
    { name: "Marcel", logo: marcel, link: "https://www.marcel.cab/", color: "#40e0d0" },
    { name: "leCab", logo: lecab, link: "https://lecab.fr/", color: "#2980b9" },
    { name: "Hermès", logo: hermes, link: "https://www.hermes.com/fr/fr/", color: "#ff8c00" },
  ];

  return (
    <div id='contact' className='bg-black max-w-[1240px] mx-auto py-6 px-4 grid lg:grid-cols-2 gap-8'>
      <div>
        <h1 className='w-full text-3xl justify-between font-bold py-12 uppercase text-white'>jac transports</h1>
        <div className='text-white py-4 flex items-center'>
          <MdOutlinePlace size={30} color='red'></MdOutlinePlace>
          <a href={googleMapsLink} target="_blank" rel="noopener noreferrer" className='text-white duration-200 hover:underline ml-4'>233 Rue du Faubourg Saint Martin, 75010 Paris</a>
        </div>
        <div className='text-white flex py-4 items-center'>
          <CiMail size={30} color='red'></CiMail>
          <a href={emailLink} className='ml-4 text-white duration-200 hover:underline'>julienakdogan.privatedriver@gmail.com</a>
        </div>
        <div className='text-white flex py-4 items-center'>
          <CiMobile3 size={30} color='red' className=''></CiMobile3>
          <p className='ml-4'> <a href="tel:0629481161" className='hover:underline duration-200'>+33 6 29 48 11 61</a></p>
        </div>
        <div className='text-white flex py-4 items-center'>
          <IoLogoInstagram size={30} color='red'></IoLogoInstagram>
          <a href={InstagramLink} target="_blank" rel='noopener noreferrer' className='ml-4 hover:underline duration-200'>JAC TRANSPORTS</a>
        </div>
      </div>

      <div className="grid grid-cols-3">
        {partenaires.map((partenaire, index) => (
            <div key={index} className={`flex flex-col items-center md:my-32 partner-item ${hoveredPartner === partenaire.name ? 'hovered' : ''}`} onMouseEnter={() => handlePartnerMouseEnter(partenaire.name)} onMouseLeave={handlePartnerMouseLeave}>
                <img src={partenaire.logo} alt={partenaire.name} className="w-16 h-16 md:w-24 md:h-24 my-6 rounded-full object-cover" />
                <p className="text-white" style={{ color: hoveredPartner === partenaire.name ? partenaire.color : 'white' }}>
                    {partenaire.name}
                </p>
            </div>
        ))}
      </div>

      <div className="flex items-center">
        <FaRegCopyright className="text-white mr-2" />
        <p className='text-white font-extralight md:py-2 sm:py-2 py-8'>2024 Tous droits réservés - <Link className='hover:text-red-600' to="/mentions-légales">Mentions légales</Link></p>
      </div>
    </div>
  );
}

export default Contact;
