import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Start from "./components/Start";
import Header from "./components/Header";
import CompanyPresentation from "./components/CompanyPresentation";
import Services from "./components/Services";
import Reservation from "./components/Reservation";
import VehicleInfo from "./components/VehicleInfo";
import Contact from "./components/Contact";
import LegalPage from "./components/LegalPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <>
            <Start />
            <Header id="home" />
            <CompanyPresentation id="about" />
            <Services id="services" />
            <Reservation id="réservation" />
            <VehicleInfo id="véhicules" />
            <Contact id="contact" />
          </>
        } />
        <Route path="/mentions-légales" element={<LegalPage />} />
      </Routes>
    </Router>
  );
}

export default App;
